"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("./core");

var _Base = _interopRequireDefault(require("./Base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class Translate extends _Base.default {
  render() {
    const {
      value,
      locale,
      ...otherProps
    } = this.props;
    const translation = (0, _core.translate)(value, otherProps, {
      locale
    });
    return translation;
  }

}

Translate.propTypes = {
  value: _propTypes.default.string.isRequired,
  locale: _propTypes.default.string
};
var _default = Translate;
exports.default = _default;
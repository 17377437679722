"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translate = exports.settings = exports.setTranslationsGetter = exports.setTranslations = exports.setLocaleGetter = exports.setLocale = exports.setHandleMissingTranslation = exports.setHandleFailedLocalization = exports.localize = exports.getTranslations = exports.getLocale = exports.forceComponentsUpdate = exports.addLocales = exports.addLocale = void 0;

var _parse = _interopRequireDefault(require("date-fns/parse"));

var _format = _interopRequireDefault(require("date-fns/format"));

var _formatDistanceToNowStrict = _interopRequireDefault(require("date-fns/formatDistanceToNowStrict"));

var _Base = _interopRequireDefault(require("./Base"));

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint global-require: "off" */

/* eslint no-console: "off" */
const settings = {
  availableLocales: {},
  localeKey: 'en',
  translationsObject: {},
  getTranslations: null,
  getLocale: null,
  handleMissingTranslation: text => text.split('.').pop(),
  handleFailedLocalization: () => null,

  get translations() {
    return this.getTranslations ? this.getTranslations() : this.translationsObject;
  },

  set translations(translations) {
    this.translationsObject = translations;
  },

  get locale() {
    return this.getLocale ? this.getLocale() : this.localeKey;
  },

  set locale(locale) {
    this.localeKey = locale;
  },

  getLocaleObject(locale) {
    const l = locale || this.locale;
    return this.availableLocales[l] || this.availableLocales[l.split('-')[0]];
  }

};
exports.settings = settings;

const getLocale = () => settings.locale;

exports.getLocale = getLocale;

const setLocale = function (locale) {
  let rerenderComponents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  settings.locale = locale;
  settings.getLocale = null;

  if (rerenderComponents) {
    _Base.default.rerenderAll();
  }
};

exports.setLocale = setLocale;

const addLocale = (name, locale) => {
  settings.availableLocales[name] = locale;
};

exports.addLocale = addLocale;

const addLocales = locales => {
  settings.availableLocales = { ...settings.availableLocales,
    ...locales
  };
};

exports.addLocales = addLocales;

const getTranslations = () => settings.translations;

exports.getTranslations = getTranslations;

const setTranslations = function (translations) {
  let rerenderComponents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  settings.translations = translations;
  settings.getTranslations = null;

  if (rerenderComponents) {
    _Base.default.rerenderAll();
  }
};

exports.setTranslations = setTranslations;

const setLocaleGetter = fn => {
  if (typeof fn !== 'function') {
    throw new Error('Locale getter must be a function');
  }

  settings.getLocale = fn;
};

exports.setLocaleGetter = setLocaleGetter;

const setTranslationsGetter = fn => {
  if (typeof fn !== 'function') {
    throw new Error('Translations getter must be a function');
  }

  settings.getTranslations = fn;
};

exports.setTranslationsGetter = setTranslationsGetter;

const setHandleMissingTranslation = fn => {
  if (typeof fn !== 'function') {
    throw new Error('Handle missing translation must be a function');
  }

  settings.handleMissingTranslation = fn;
};

exports.setHandleMissingTranslation = setHandleMissingTranslation;

const setHandleFailedLocalization = fn => {
  if (typeof fn !== 'function') {
    throw new Error('Handle failed localization must be a function');
  }

  settings.handleFailedLocalization = fn;
};

exports.setHandleFailedLocalization = setHandleFailedLocalization;

const translate = function (key) {
  let replacements = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const locale = options.locale || settings.locale;
  let translation = '';

  try {
    const translationLocale = settings.translations[locale] ? locale : locale.split('-')[0];
    translation = (0, _utils.fetchTranslation)(settings.translations, "".concat(translationLocale, ".").concat(key), replacements.count);
  } catch (err) {
    if (options.returnNullOnError) return null;
    if (options.returnKeyOnError) return key;
    return settings.handleMissingTranslation(key, replacements, options, err);
  }

  return (0, _utils.replace)(translation, replacements);
};

exports.translate = translate;

const localize = function (value) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const locale = options.locale || settings.locale;

  if (options.dateFormat) {
    try {
      const localeObject = settings.getLocaleObject(locale);
      if (!localeObject) throw new Error("Locale ".concat(locale, " not added"));
      const parsedDate = options.parseFormat ? (0, _parse.default)(value, translate(options.parseFormat, {}, {
        locale,
        returnKeyOnError: true
      }), new Date(), {
        locale: localeObject
      }) : new Date(value);

      if (options.dateFormat === 'distance-to-now') {
        return (0, _formatDistanceToNowStrict.default)(parsedDate, {
          addSuffix: true,
          locale: localeObject
        });
      }

      if (options.dateFormat === 'distance-to-now-hours') {
        return (0, _formatDistanceToNowStrict.default)(parsedDate, {
          addSuffix: true,
          unit: 'hour',
          locale: localeObject
        });
      }

      if (options.dateFormat === 'distance-to-now-days') {
        return (0, _formatDistanceToNowStrict.default)(parsedDate, {
          addSuffix: true,
          unit: 'day',
          locale: localeObject
        });
      }

      return (0, _format.default)(parsedDate, translate(options.dateFormat, {}, {
        locale,
        returnKeyOnError: true
      }), {
        locale: localeObject
      });
    } catch (err) {
      return settings.handleFailedLocalization(value, options, err);
    }
  }

  if (typeof value === 'number') {
    try {
      return new Intl.NumberFormat(locale, options).format(value);
    } catch (err) {
      return settings.handleFailedLocalization(value, options, err);
    }
  }

  return value;
};

exports.localize = localize;

const forceComponentsUpdate = () => {
  _Base.default.rerenderAll();
};

exports.forceComponentsUpdate = forceComponentsUpdate;
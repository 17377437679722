"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _core = require("./core");

var _Base = _interopRequireDefault(require("./Base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class Localize extends _Base.default {
  render() {
    const {
      value,
      dateFormat,
      parseFormat,
      options = {}
    } = this.props;
    const localization = (0, _core.localize)(value, { ...options,
      dateFormat,
      parseFormat
    });
    return localization;
  }

}

Localize.propTypes = {
  value: _propTypes.default.oneOfType([_propTypes.default.string, _propTypes.default.number, _propTypes.default.object]).isRequired,
  dateFormat: _propTypes.default.string,
  parseFormat: _propTypes.default.string,
  options: _propTypes.default.object
};
var _default = Localize;
exports.default = _default;
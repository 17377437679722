"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Base extends _react.default.Component {
  static rerenderAll() {
    Base.instances.forEach(instance => instance.forceUpdate());
  }

  componentDidMount() {
    Base.instances.push(this);
  }

  componentWillUnmount() {
    Base.instances.splice(Base.instances.indexOf(this), 1);
  }

}

exports.default = Base;

_defineProperty(Base, "instances", []);
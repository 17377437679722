import { Link } from 'react-router-dom'

export function About() {
    return (
        <div>
            <section>
                <h2>About</h2>
                <p>
                    <b>Fasting Monster is a free service.</b> It can be used as
                    mobile app on iPhone and iPads. It also can be used as
                    webapp on any device with a browser.
                </p>
                <p>
                    <b>We respect users' privacy.</b> Therefore we do not
                    collect personal data (only e-mail-address is required). We
                    also do not force users to share data with third parties.
                </p>
                <p>
                    <b>You own your data</b>. You can download your data and
                    also delete your account permanently. Without hustle.
                </p>
                <p>
                    <Link to="/privacy">Here is our privacy policy.</Link>
                </p>
                <p>
                    <a href="mailto:info@zeitpulse.com">info@zeitpulse.com</a>
                </p>
            </section>
        </div>
    )
}

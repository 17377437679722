import ReactDOM from 'react-dom'
import {
    BrowserRouter,
    Link,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom'
import { About } from './frontpage/About'
import { Frontpage } from './frontpage/Frontpage'
import { Privacy } from './frontpage/Privacy'

function App() {
    const appUrl = 'https://app.fasting.monster'
    const location = useLocation()
    return (
        <div>
            <header id="top-navi">
                <nav>
                    <li>
                        <Link
                            to={location.pathname === '/about' ? '/' : 'about'}
                            className="button"
                        >
                            About
                        </Link>
                    </li>
                    <li>
                        <a href={appUrl} className="button">
                            Login
                        </a>
                    </li>
                    <li>
                        <a
                            href={appUrl + '/signup'}
                            className="button primary signup"
                        >
                            Signup
                        </a>
                    </li>
                </nav>
            </header>
            <main>
                <Routes>
                    <Route path="/" element={<Frontpage></Frontpage>} />
                    <Route path="about" element={<About></About>} />
                    <Route path="privacy" element={<Privacy></Privacy>} />
                </Routes>
            </main>
            <footer>
                &copy; 2022 - {new Date().getFullYear()}
                <div>
                    <a href={appUrl}>app.fasting.monster</a> <br></br>
                    {/* <Link to="/about">about fasting monster</Link> */}
                </div>
            </footer>
        </div>
    )
}

const app = document.getElementById('frontpage')
ReactDOM.render(
    <BrowserRouter>
        <App></App>
    </BrowserRouter>,
    app
)

// https://codepen.io/alsheuski/pen/eJNwNX

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _Base = _interopRequireDefault(require("./Base"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class I18n extends _Base.default {
  constructor() {
    super(...arguments);

    _defineProperty(this, "render", () => this.props.render());
  }

}

I18n.propTypes = {
  render: _propTypes.default.func.isRequired
};
var _default = I18n;
exports.default = _default;
import monsterSVG from '../../images/monster-alone.svg'
import clockSVG from '../../images/frontpage/streamline-icon-time-clock-nine@48x48.SVG'
import countdownSVG from '../../images/frontpage/streamline-icon-smart-house-timer@48x48.SVG'
import historySVG from '../../images/frontpage/streamline-icon-list-to-do@48x48.SVG'
import foodSVG from '../../images/frontpage/streamline-icon-asian-food-noodles-bowl@48x48.SVG'
import moonSVG from '../../images/frontpage/streamline-icon-weather-night-clear@48x48.SVG'
import downloadSVG from '../../images/frontpage/streamline-icon-data-file-bars-download@48x48.SVG'
import graphSVG from '../../images/frontpage/streamline-icon-analytics-graph-lines@48x48.SVG'
import shieldSVG from '../../images/frontpage/streamline-icon-shield-check@48x48.SVG'
import appStoreLogo from '../../images/frontpage/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'

import { isMobileDevice } from '../shared/lib/helper'

import { useEffect, useState } from 'react'
import Plyr from 'plyr'
import { Link } from 'react-router-dom'

function VideoPreview(props) {
    const [videoTag, setVideoTag] = useState(
        document.getElementById(props.videoId)
    )
    const [isMobile, setIsMobile] = useState(isMobileDevice())
    const videoId = 'video-' + props.videoId

    useEffect(() => {
        if (isMobile) new Plyr(`#${videoId}`)
    })

    return (
        <div>
            <video
                id={videoId}
                className="video-player"
                autoPlay={!isMobile}
                controls={isMobile}
                playsInline={!isMobile}
                muted
                loop
                // preload={isMobile ? 'none' : 'auto'}
            >
                <source
                    src={videoTag
                        .querySelector('source[type="video/webm"')
                        .getAttribute('src')}
                    type="video/webm"
                />
                <source
                    src={videoTag
                        .querySelector('source[type="video/mp4"')
                        .getAttribute('src')}
                    type="video/mp4"
                />
            </video>
        </div>
    )
}

export function Frontpage() {
    return (
        <>
            <section id="opener">
                <a href="https://app.fasting.monster/signup">
                    <div id="opener-lettering">Fasting Monster</div>
                    <img src={monsterSVG}></img>
                </a>
            </section>
            <section>
                <h2>Features</h2>
                <div className="feature-box">
                    <ul>
                        <li>
                            <img src={clockSVG}></img>
                            <div>Various Fasting Intervals</div>
                            <VideoPreview videoId="video_intervals"></VideoPreview>
                        </li>
                        <li>
                            <img src={countdownSVG}></img>
                            <div>Flexible Time Counter</div>
                            <VideoPreview videoId="video_startstop"></VideoPreview>
                        </li>
                        <li>
                            <img src={historySVG}></img>
                            <div>
                                View and edit history of fasting intervals
                            </div>
                            <VideoPreview videoId="video_fastingedit"></VideoPreview>
                        </li>
                        <li>
                            <img src={foodSVG}></img>
                            <div>Tracks your food and drink intake</div>
                            <VideoPreview videoId="video_intakes"></VideoPreview>
                        </li>
                        <li>
                            <img src={graphSVG}></img>
                            <div>Statistics</div>
                            <VideoPreview videoId="video_statistics"></VideoPreview>
                        </li>
                        <li>
                            <img src={downloadSVG}></img>
                            <div>Export all your data as spreadsheet file</div>
                            <VideoPreview videoId="video_download"></VideoPreview>
                        </li>
                        <li>
                            <img src={moonSVG}></img>
                            <div>Dark Mode</div>
                            <VideoPreview videoId="video_darkmode"></VideoPreview>
                        </li>
                        <li>
                            <img src={shieldSVG}></img>
                            <div>Privacy first</div>
                        </li>
                    </ul>
                </div>
                <a
                    href="https://apps.apple.com/de/app/fasting-monster/id6443463164"
                    target="_blank"
                >
                    <img src={appStoreLogo} style={{ width: '200px' }}></img>
                </a>
            </section>
        </>
    )
}

import { addHours, formatRelative, format, min } from 'date-fns'
import * as DateFnsLocales from 'date-fns/locale'
import { t } from 'react-i18nify'

function addHumanizedAttributesToFasting(fasting, locale = 'en') {
    fasting.target_end_humanize = formatRelative(
        addHours(new Date(fasting.starts_at), fasting.targeted_hours),
        new Date(),
        { addSuffix: true, locale: DateFnsLocales[locale] }
    )
    return fasting
}

function isMobileDevice() {
    return window.matchMedia('(max-width: 800px)').matches
}

function numberWithFraction(number) {
    let decimal = Number(number) - Math.floor(Number(number))
    if (decimal === 0) {
        return Math.round(number)
    }
    number = Math.floor(number)
    if (decimal <= 0.125) {
        // floor
        return number
    }
    if (decimal <= 0.295) {
        return `${number}¼`
    }
    if (decimal <= 0.42) {
        return `${number}⅓`
    }
    if (decimal <= 0.58) {
        return `${number}½`
    }
    if (decimal <= 0.71) {
        return `${number}⅔`
    }
    if (decimal <= 0.84) {
        return `${number}¾`
    }
    // ceil
    return number + 1
}

function fastingHours(fasting) {
    return fasting.duration_in_minutes / 60
}

function hoursUnit(fastingHours) {
    return Number(fastingHours) >= 2 || Number(fastingHours) < 1
        ? t('hours')
        : t('hour')
}

function timeZonedTimeFromIsoDateString(isoDateString) {
    return format(new Date(isoDateString), 'HH:mm')
}

/**
 * Use this get a date time formatted for datetime-local html input
 * @param {*} isoDateString
 * @returns
 */
function dateIsoToDateTimeLocalFormat(isoDateString) {
    // https://stackoverflow.com/questions/30166338/setting-value-of-datetime-local-from-date
    return new Date(
        new Date(isoDateString).getTime() +
            new Date().getTimezoneOffset() * -60 * 1000
    )
        .toISOString()
        .slice(0, 19)
}

function cssVariableValueFromDocument(key) {
    return getComputedStyle(document.querySelector('body')).getPropertyValue(
        key
    )
}

function formatDateForLocale(date, locale, formatStr = 'PP') {
    return format(date, formatStr, {
        locale: DateFnsLocales[locale],
    })
}

function filterFastingsForChart(d) {
    let _earliestDate = null
    let _latestDate = null

    let data = d.data.reverse()

    let fastings = data.map((r, i) => {
        if (i === 0) {
            _earliestDate = r['starts_at']
        }
        if (i === data.length - 1) {
            _latestDate = r['starts_at']
        }
        return {
            date: i,
            hours: Number(r['duration_in_hours']),
            label:
                new Date(r['starts_at']).toLocaleDateString() +
                `\n ${numberWithFraction(r['duration_in_hours'])} hours`,
        }
    })
    let targets = data.map((r, i) => {
        return {
            date: i,
            hours: Number(r['targeted_hours']),
        }
    })

    return {
        fastings,
        targetedHours: targets,
        currentPage: d.meta.current_page,
        earliestDate: _earliestDate,
        latestDate: _latestDate,
        nextPage: d.links.next?.match(/=(\d+)$/)[1],
        prevPage: d.links.prev?.match(/=(\d+)$/)[1],
    }
}

function intakeRelativeTimeFromFasting(fasting, intake) {
    let diff =
        new Date(intake.created_at).getTime() -
        new Date(fasting.starts_at).getTime()
    let hours = diff / 1000 / 60 / 60
    let minutes = Math.ceil((hours - Math.floor(hours)) * 60)
    return `${String(Math.floor(hours)).padStart(2, '0')}:${String(
        minutes
    ).padStart(2, '0')}`
}

function calculateProgressBarForMobileDevice({ width, height } = {}) {
    let windowHeight = height
    let windowWidth = width

    let isLandscape = windowHeight < windowWidth
    let isTablet = !isLandscape ? windowHeight > 1200 : windowWidth > 1200
    let timerLeft = 0
    let left = 0
    let top = 0

    if (isTablet) {
        height = 450
        width = height
    } else {
        height = 320
        width = height
    }

    let timerFontSize = height / 12
    let borderRadius = isTablet
        ? Math.round(height / 2.2)
        : Math.round(height / 2.4)
    let timerTop = height / 2 - Math.round(timerFontSize / 2) - 0

    if (isTablet) {
        left = (-1 * Math.round(width)) / 6
    } else {
        left = (-1 * Math.round(width)) / 20
    }

    return {
        height,
        width,
        borderRadius,
        timerTop,
        timerLeft,
        left,
        top,
        timerFontSize,
    }
}

export {
    addHumanizedAttributesToFasting,
    numberWithFraction,
    dateIsoToDateTimeLocalFormat,
    hoursUnit,
    fastingHours,
    timeZonedTimeFromIsoDateString,
    cssVariableValueFromDocument,
    isMobileDevice,
    t,
    formatDateForLocale,
    filterFastingsForChart,
    intakeRelativeTimeFromFasting,
    calculateProgressBarForMobileDevice,
}
